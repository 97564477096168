<template>
  <div class="zone" v-loading="exporting" :element-loading-text="loadingText">
    <functionBox  :titleData="{permission_flag:'SparePart',nowID:1}" :isAssetCheck = "true" :notes="notes ? notes : ''"
                  @searchBtn="searchBtn" @addCheck="editCheck('0')" @exportCheck="exportCheckHandler" @clearSearch="clearSearch" :searhLoading="loading">
      <template>
        <li>
          <el-input placeholder="请输入盘点单编号" v-model="searchParams.note_no" clearable size="small"></el-input>
        </li>
        <li>
          <el-input placeholder="请输入盘点单名称" v-model="searchParams.title" clearable size="small"></el-input>
        </li>
        <li>
          <el-select v-model="searchParams.note_status" placeholder="请选择盘点状态" size="small" filterable clearable>
            <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          <el-date-picker
              size="small"
              v-model="check_time"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="marginLeft:10px"
              class="Calendar"
              @clear="clearSearchTime"
          />
        </li>
      </template>
    </functionBox>
    <div class="sheet"  v-loading="loading" :element-loading-text="loadingText">
      <el-table :data="tableData" stripe  :height="tableSize== 'big' ? '960' : tableSize== 'middle' ?'630' : '430'" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="note_no" label="盘点编号" width="220" align="center"></el-table-column>
        <el-table-column prop="title" label="盘点名称"  min-width="150" align="center"></el-table-column>
        <el-table-column prop="checkTime" label="盘点时间"  min-width="280" align="center">
          <template v-slot="{row}">
            <span>{{row.start_time.slice(0,10)}}~{{row.end_time.slice(0,10)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="note_status" label="状态"  min-width="100" align="center">
          <template v-slot="{row}">
            <el-tag size="small" class="status-tag" :class="handleStatus(row.note_status).className">{{handleStatus(row.note_status).statusName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="应盘总数"  width="100" align="center"></el-table-column>
        <el-table-column prop="total" label="已盘总数"  width="100" align="center">
          <template v-slot="{row}">
            <span>{{row.complete_total}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="创建日期"  min-width="150" align="center">
          <template v-slot="{row}">
            <span>{{row.update_time.slice(0,10)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="user_name" label="创建人"  width="90" align="center"></el-table-column>
        <el-table-column prop="" label="操作" :width="tableSize== 'big' ? '450' : tableSize== 'middle' ?'340' : '280'" align="left" fixed="right">
          <template v-slot="{row}">
            <el-button size="mini" class="dtl-btn" icon="el-icon-s-promotion" @click="goDtl(row)">详情</el-button>
            <el-button size="mini" v-if="[1500].includes(row.note_status) && user_id == row.user"  @click="editCheck('1',row)" icon="el-icon-edit-outline" class="edit-btn">编辑</el-button>
            <el-button size="mini" v-if="[1502].includes(row.note_status) && (assetMGtObj.checkExport || [110, 103, 109].includes(userRoleId))"  icon="el-icon-download" @click="exportCheck(row,'1')" class="download-btn">下载</el-button>
            <el-button size="mini" v-if="[1500].includes(row.note_status) && user_id == row.user"  @click="handleDelete(row)" icon="el-icon-delete" class="delete-btn">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="el_pagination_style">
        <el-pagination
            :hide-on-single-page="total <= 0"
            background
            layout="total,sizes,prev, pager, next,jumper"
            :total="total"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="size"
            :current-page.sync="page"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
        />
      </div>
      <el-dialog
          title="下载信息确认"
          :visible.sync="confirmDialog"
          width="30%"
          :before-close="handleClose">
        <div class="export">
<!--          <p  style="font-size: 20px;font-weight: bold">请确认信息</p>-->

<!--          <p>文件类型：{{fileType == 2 ? 'PDF' : fileType == 1 ?  'CSV': 'EXCEL'}}</p>-->
          <p v-if="selectedNotes.length > 0">盘点单编号：<p v-for="item in selectedList"> {{item.toString()}}</p></p>
          <p v-if="searchParams.title">盘点单名称：{{searchParams.title}}</p>
          <p v-if="searchParams.note_status">盘点状态：{{searchParams.note_status==1500 ? '待盘点' : searchParams.note_status==1501 ? '待盘点' :'已完成' }}</p>
          <p v-if="check_time && check_time[0] && check_time[0].length > 0">时间范围：{{check_time[0]}}~{{check_time[1]}}</p>
          <p style="color: red;">导出文件时间可能较长请耐心等待</p>
          <!--          <p v-if="message">{{message}}</p>-->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDialog = false">取 消</el-button>
          <el-button type="primary" @click="handleConfirmExport()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import TJDetailTitle from '@/components/TJDetailTitle'
import timePicker from '@/components/timePicker'
// import { hospitals_list,organization_list } from '@/api/search.js'
import FunctionBox from '../../views/Statistics/components/functionBox.vue'
import PlatSelect from "@/components/PlatSelect.vue";
import {getInventoryList, deleteCheck, exportCheck} from "@/api/assetCheck";
import checkCommon from "@/views/AssetCheck/mixin/checkCommon";
import {addMenu, getAssetMgtBtn} from "@/router/menuMain";
import {getToken} from "@/utils/auth";

export default {
  components: {PlatSelect, TJDetailTitle, timePicker, FunctionBox},
  name: 'acList',  // 资产管理列表
  mixins: [checkCommon],
  data() {
    return {
      userRoleId: JSON.parse(localStorage.getItem('userInfo')).org_post,
      confirmDialog: false,
      assetMGtObj:{
        checkExport: false, //盘点导出
      },
      tableSize: '',
      user_id: '',
      page: 1,
      size: 10,
      total: 0,
      notes: '',
      exporting: false, //加载动画
      loading: false, //加载动画
      tableData: [],
      check_time: [],
      selectedNotes: [],
      selectedList: [],
      selectedRows: [],
      is_export: false,
      tableHeight: 0,
      searchType: '0',
      message: '',
      searchParams:{
        note_status: '',
        title: '',
        note_no: '',
        start_time: '',
        end_time: '',
      },
      pageParams:{
        page: 1,
        size:10,
      },
      loadingText: '数据加载中，请稍后'
    }
  },
  created() {
    this.getTableHeight()
    let menuList = JSON.parse(localStorage.getItem('menuList'))
    addMenu(menuList);
    this.assetMGtObj = getAssetMgtBtn()// 缓存里取按钮权限
    this.getList()
    this.user_id = JSON.parse(localStorage.getItem("userInfo")).user_id
    if(window.innerWidth<1550){
      this.tableSize = 'small'
    }else if(window.innerWidth < 2400 && window.innerWidth > 1550){
      this.tableSize = 'middle'
    }else{
      this.tableSize = 'big'
    }
  },
  methods: {
    getTableHeight(){
      var viewportHeight = window.innerHeight;
      var viewportWidth =  window.innerWidth
      // var element = document.getElementById('pagination');
      if(viewportWidth > 2400){
        this.tableHeight = viewportHeight - 500
      }else if(viewportWidth < 2400 &&  viewportWidth > 1550){
        this.tableHeight = viewportHeight - 400
      }else{
        this.tableHeight = viewportHeight - 600
      }
    },
    getList(){
      this.loading = true
      this.loadingText = '数据加载中，请稍后'
      if(this.searchType != '1' ){   //searchType = '0' 从详情返回的时候，需要获取缓存的搜索条件
        if(sessionStorage.getItem('AcPageParams')){
          this.pageParams = JSON.parse(sessionStorage.getItem('AcPageParams')).pageParams
          this.size = this.pageParams.size || 10
          this.page=this.pageParams.page || 1
        }
        if(JSON.parse(sessionStorage.getItem('AcSearchParams'))){
          this.searchParams = {...JSON.parse(sessionStorage.getItem('AcSearchParams')).searchParams};
          if(this.searchParams && this.searchParams.start_time && this.searchParams.end_time){
            this.check_time = [this.searchParams.start_time,this.searchParams.end_time]
          }else{
            this.check_time = []
          }
        }
      }
      if(this.check_time && this.check_time.length>0){
        this.searchParams.end_time = this.check_time[1]
        this.searchParams.start_time = this.check_time[0]
      }else{
        this.searchParams.end_time = ''
        this.searchParams.start_time = ''
      }
      let params = {
        ...this.searchParams,
        page:  this.pageParams.page,
        size: this.pageParams.size
      }
      getInventoryList(params).then(res=>{
        this.total = res.data.count
        this.tableData = res.data.data
        this.loading = false
      }).catch(err =>{
        this.loading = false
        if( err.status === 'error' && this.searchParams.page >1 ){
          this.searchParams.page--
          this.getList()
        }
    })
    },
    handlePlatSelect(val){
      this.platform_id = val
    },
    searchBtn() {  // 搜索
      this.page = 1
      if(this.check_time){
      }else{
        this.searchParams.start_time = ''
        this.searchParams.end_time = ''
      }
      this.searchType = '1'
      this.getList();
      sessionStorage.setItem('AcSearchParams', JSON.stringify({ searchParams:this.searchParams }));
      sessionStorage.setItem('AcPageParams', JSON.stringify({ pageParams:{page: 1,size:10} }));
    },
    handleCurrentChange(val) { // 翻页
      this.page = val
      this.pageParams.size = this.size
      this.pageParams.page = this.page
      sessionStorage.setItem('AcPageParams', JSON.stringify({ pageParams:this.pageParams }));
      this.getList('1')
    },
    clearSearch(){  // 重置
      sessionStorage.setItem('AcSearchParams', JSON.stringify({ searchParams:{}}));
      this.searchParams.title = ''
      this.searchParams.note_no = ''
      this.searchParams.note_status = ''
      this.searchParams.start_time = ''
      this.searchParams.end_time = ''
      this.check_time = []
      this.getList('1');

    },
    clearSearchTime(){
      this.searchParams.start_time = ''
      this.searchParams.end_time = ''
      this.check_time = []
      this.getList();
    },
    handleDelete(row){
      const h = this.$createElement
      this.$confirm('提示', {
        title: '提示',
        message: h('div', [h('p', `确认删除这条数据？`),]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteCheck({note_id:row.note_id}).then(res=>{
          if(res.code == 200){
            this.$message.success('删除成功！')
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消确认',
        })
      })
    },
    handleSizeChange(val){
      this.pageParams.size = val
      this.pageParams.page = this.page
      sessionStorage.setItem('AcPageParams', JSON.stringify({ pageParams:this.pageParams }));
      this.getList('1');
    },
    goDtl(row){
      let page_type
      if(row.note_status === 1502){
        page_type = 'report'
      }else{
        page_type = 'dtl'
      }
      sessionStorage.setItem('AcSearchQuery', JSON.stringify({ searchParams: this.searchParams }));
      this.$router.push({ path: '/assetCheck/dtl',query: {note_id: row.note_id,page_type,note_status:row.note_status,complete_total: row.complete_total,total: row.total}  })
      this.searchType = '0'
    },
    editCheck(type,row) {
      if(type === '0'){
        this.$router.push({ path: '/assetCheck/edit' })
      }else{
        this.$router.push({ path: '/assetCheck/edit',query: {note_id: row.note_id } })
      }
    },
    handleSelectionChange(val){
      this.selectedRows = val
      this.selectedNotes = val.map(item => item.note_id)
      this.selectedList = val.map(item => item.note_no)
      let note_status_list = val.map(item => item.note_status.toString())
      if(note_status_list.includes('1500') ||   note_status_list.includes('1501')){
        this.is_export = false
      }else{
        this.is_export = true
      }
    },
    exportCheck(row,type){
      let that = this
      if(type && type === '1'){
        this.is_export = true
      }
      if(this.selectedNotes.length > 0 || (row && row.note_id)){
        let note_ids
        if(row){
          note_ids = row.note_id
        }else{
          note_ids = this.selectedNotes.toString()
        }
        if(this.is_export){
          this.exporting = true
          this.loadingText = '正在下载文件，请稍后'
          exportCheck({notes: note_ids}).then(res =>{
            that.fileList = res.data
            let downLoadList = that.fileList.map(item => item.file_url)
            this.downloadFiles(downLoadList)
          }).finally(()=>{
            this.exporting = false
          })
        }else{
          this.$message.error('请选择已完成的数据进行导出！')
        }
      }else{
        this.$message.error('请先勾选需要导出的数据！')
      }
    },
    downloadFile(url) {
      return new Promise((resolve, reject) => {
        const fileName = url.split('/').pop()
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.download = fileName
        // 这个延迟操作的目的是为了确保浏览器有足够的时间来处理下载操作
        // 这样可以避免在循环中快速触发多个下载操作，导致浏览器无法正确处理下载请求
        link.addEventListener('click', () => {
          setTimeout(() => {
            resolve()
          }, 1000)
        })
        link.click()
      }).finally(()=>{this.exporting = false})
    },
    async downloadFiles(urlArr) {
      for (const url of urlArr) {
        await this.downloadFile(url)
      }
    },
    handleClose(){
      this.confirmDialog = false
    },
    exportCheckHandler(){
      if(this.selectedNotes.length < 1){
        this.$message.error('请选择需要导出的盘点单！')
      }else{
        this.confirmDialog = true
      }
    },
    handleConfirmExport(){  //导出弹窗确认
      this.confirmDialog = false
      // if(this.selectedNotes.length > 0){
        this.exportCheck(null, null)
      // }else{
      //   this.$message.error('请选择需要导出的盘点单！')
      // }
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
::v-deep .el-table th:first-child.el-table__cell>.cell{
  padding-left:14px!important;
}
::v-deep .el-pagination__sizes{
  width: 80px;
  vertical-align: top;
}
::v-deep .el-pagination__sizes .el-select{
  vertical-align: top;
}
::v-deep .el-pagination__sizes input{
  width: 80px;
  height: 1.8rem;
  border: 1px solid #999999;
}
::v-deep .el-pagination .el-input__suffix{
  right: 22px;
}
::v-deep .el-range-editor--small .el-range-input{
  font-size: 0.75rem!important;
}
.export{
  p{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
}
.wait{
  background: #9A9A9A;
  color:#fff;
  font-size: px(14);
}
.checking{
  background: #567722;
  color:#fff;
  font-size: px(14);

}
.completed{
  background: #4095E5;
  color:#fff;
  font-size: px(14);
}
</style>
